import request from './config'

/*
 * 获取平台账号列表
 * params { page,pageSize,platform,uname }
 */
export function getAccountList(params) {
  return request.get('/admin/account/all', {params})
}

/*
 * 编辑平台账号
 * params { uname,password,platform }
 */
export function editAccount({id, ...data}) {
  if (id) {
    return request.put(`/platform/account/${id}`, data)
  } else {
    return request.post('/platform/account', data)
  }
}

/*
 * 删除平台账号
 * id 账号ID
 */
export function deleteAccount(id) {
  return request.delete(`/admin/account/${id}`)
}

/*
 * 获取登陆者公众号列表
 * params { platform,channelName }
 */
export function getOfficialList(params) {
  return request.get('/admin/channel', {params})
}

/*
 * 获取公众号消耗列表
 * id 公众号ID
 * params { page,pageSize,startDate,endDate }
 */
export function getConsumeList(id, params) {
  return request.get(`/admin/cost/${id}`, {params})
}

/*
 * 编辑平台账号
 * params { selectDate,costAmount }
 */
export function editConsume({id, ...data}) {
  if (id) {
    return request.put(`/admin/cost/${id}`, data)
  } else {
    return request.post('/admin/cost', data)
  }
}

/*
 * 删除消耗数据
 * id 账号ID
 */
export function deleteConsume(id) {
  return request.delete(`/admin/cost/${id}`)
}

/*
 * 获取公众号统计列表
 * params { page,pageSize,accountChannelId，beginDate,endDate }
 */
export function getStaticsList(params) {
  return request.get('/admin/order/statistics', {params})
}

/*
 * 同步公众号订单
 * id 公众号id
 * params { beginDate,endDate,orderType}
 */
export function syncOrder(id, params) {
  return request.put(`/platform/order/hand/${id}`, params)
}

/*
 * 查询所有书籍
 * params { page,epageSizendDate,bookName}
 */
export function getBookList(params) {
  return request.get('/admin/books/all', {params})
}

/*
 * 编辑书籍
 * params { bookName }
 */
export function editBook({id, ...data}) {
  if (id) {
    return request.put(`/put/books/${id}`, data)
  } else {
    return request.post('/put/books', data)
  }
}

/*
 * 删除书籍
 * id 书籍ID
 */
export function deleteBook(id) {
  return request.delete(`/admin/books/${id}`)
}

/**
 * 获取所有公众号
 */
export function getAllAccount(params) {
  return request.get('/admin/channel/all', {params})
}

/**
 * 获取指定用户的公众号
 * adminUserId 用户id
 */
export function getUserAccount(adminUserId, accountType) {
  return request.get(`/admin/channel/${adminUserId}?accountType=${accountType}`)
}

/**
 * 获取公众号管理者
 * accountChannelId 公众号id
 */
export function getAccountUser(accountChannelId) {
  return request.get(`/admin/channel/user/${accountChannelId}`)
}

/**
 * 给指定用户设置关联公众号
 * adminUserId 用户id
 * accountChannelIds 公众号id
 */
export function setUserAccount({adminUserId, accountChannelIds, type, accountType, relations}) {
  return request.post('/admin/userChannel', {adminUserId, accountChannelIds, type, accountType, relations})
}

// 获取登录异常账号列表
export function getAbnormalAccount() {
  return request.get('/admin/account/abnormal')
}

// 获取模板发送人员列表
export function getTemplateUser(params) {
  return request.get('/admin/template/user', {params})
}

// 编辑模板发送人员
export function editTemplateUser({id, ...data}) {
  if (id) {
    return request.put(`/admin/template/user/${id}`, data)
  } else {
    return request.post('/admin/template/user', data)
  }
}

// 删除模板发送人员
export function deleteTemplateUser(id) {
  return request.delete(`/admin/template/user/${id}`)
}

// 获取模板列表
export function getTemplateList() {
  return request.get('/admin/template')
}

// 获取当前账号下的公众号列表
export function getAccountOfficial(accountId, channelName) {
  return request.get(`/admin/channel/account/${accountId}`, {params: {channelName}})
}

// 修改是否发送模板消息推送
export function setSendStatus(ids, params) {
  return request.put(`/admin/channel/${ids}`, params)
}

// 修改账户利润利率
export function setAccountProfit(id, profitRate) {
  return request.put(`/admin/account/profitRate/${id}`, {profitRate})
}

// 获取微信登录过期账号
export function getWxAbnormalAccount() {
  return request.get('/admin/channel/wxLogin')
}

// 同步充值回本
export function syncRecharge(accountChannelId, params) {
  return request.put(`/admin/rechargeReturn/sync/${accountChannelId}`, params)
}

// 查询所有用户
export function getAllUsers() {
  return request.get('/admin/adminUser/noPage/list', {
    params: {
      role: 1
    }
  })
}

// 同步小说成本
export function syncNovelCost(accountChannelId, params) {
  return request.put(`/admin/rechargeReturn/costRecharge/${accountChannelId}`, params)
}

// 获取所有公众号
export function getAllChannel(params) {
  return request.get('/admin/channel/all/status', {params})
}

// 修改公众号标签
export function changeTags(accountChannelId, label) {
  return request.put(`/admin/channel/label/${accountChannelId}?label=${label}`)
}

// 修改利率
export function changeRates(accountChannelId, rate) {
  return request.put(`/admin/channel/rate/${accountChannelId}?rate=${rate}`)
}

// 修改利率
export function changeChannelName(id, params) {
  return request.put(`/admin/channel/name/${id}`, params)
}

// 查询公众号标签
export function getChannelCategory(accountChannelId) {
  return request.get(`/platform/accountChannelCategory?accountChannelId=${accountChannelId}`)
}

// 给公众号打标签
export function editChannelCategory(params) {
  return request.post(`/platform/accountChannelCategory`, params)
}

// 获取广告主链接
export function getAdvertCodeLink() {
  return request.get('/put/ad/getAuthorizeUrl')
}

// 获取书籍公众号列表
export function getBookAccount(bookId, params) {
  return request.get(`/admin/books/channel/${bookId}`, {params})
}

// 获取登录信息列表
export function getLoginAccount(params) {
  return request.get('/platform/login', {params})
}

// 获取登录信息详情
export function getAccountDetail(id) {
  return request.get(`/platform/login/${id}`)
}

// 新增登录账号
export function addAccountLogin(data) {
  return request.post('/platform/login', data)
}

// 编辑登录账号
export function editAccountLogin(id, data) {
  return request.put(`/platform/login/${id}`, data)
}

// 删除登录账号
export function deleteAccountLogin(id) {
  return request.delete(`/platform/login/${id}`)
}

// 同步账号下公众号
export function synAccountChannel(params) {
  return request.put('/platform/change/accountChannel', params)
}

// 获取花生书城token(重新登录获取token)
export function getAccountPeanutToken(params) {
  return request.get('/platform/account/peanut/token', {params})
}

// 获取花生书城验证信息
export function getAccountPeanutCode(params) {
  return request.get('/platform/account/peanut/code', {params})
}

// 解除广告绑定
export function unbindAd(id) {
  return request.put(`/admin/baiduApi/channel/status/${id}`)
}

/**
 *( (  \.---./  ) )
 * '.__/o   o\__.'
 *百度账号相关     by devT
 **/

// 查询账号密码
export function getBadiuApi(params) {
  return request.get('/admin/baiduApi', {params})
}

// 修改现金基数
export function updateCashBase(id, {cashBase, novelCashBase}) {
  return request.put(`/admin/baiduApi/cashBase/${id}?cashBase=${cashBase}&novelCashBase=${novelCashBase}`)
}

// 新增账号密码
export function addBadiuApi(params) {
  return request.post('/admin/baiduApi', params)
}

// 修改账号密码
export function updateBadiuApi(id, params) {
  return request.put(`/admin/baiduApi/${id}`, params)
}

// 查询api下子账号
export function getBaiduApiChannel(params) {
  return request.get('admin/baiduApi/channel', {params})
}

// 百度账号导入文件

export function putBaiduFile(id, params) {
  return request.post(`/admin/baiduApi/childChannel?id=${id}`, params)
}

// 增加子账号与公众号关联

export function addBaiduApiChannelRelation(params) {
  return request.put('/admin/baiduApi/channel', params)
}

// 刷新账号下子账号
export function refreshBaiduApiChannelRelation(id) {
  return request.put(`/admin/baiduApi/handChannel/${id}`)
}

// /put/baiDuCreative百度今日创意
export function getBaiDuCreative(params) {
  return request.get('/put/baiDuCreative', {params})
}

// 百度创意分时（分日）统计
export function getBaiDuCreativeStats(params) {
  return request.get('/put/baiDuCreative/stats', {params})
}

// 清除登录状态

export function clearAccountCache(id) {
  return request.delete(`/platform/login/cookie/${id}`)
}

// 分页查询adq信息和详情查询

export function getADQList(params) {
  return request.get('/put/adq', {params})
}

// 查询adq列表

export function checkADQList(params) {
  return request.get('/put/adq/list', {params})
}

//  绑定公众号 书籍

export function bindAccount(params) {
  return request.put('put/adq', params)
}

// 新增巨量引擎账号
export const addOeAccount = (params) => request.post('/admin/oeLogin', params)

// 获取巨量引擎账号

export const getOeAccount = (params) => request.get('/admin/oeLogin', {params})

// 修改

export const editOeAccount = (id, params) => request.put(`/admin/oeLogin/${id}`, params)

// 删除

export const delOeAccount = (id) => request.delete(`/admin/oeLogin/${id}`)


// 绑定巨量账号使用人员

export const bindOeAccountUser = (params) => request.post('/admin/oeLogin/bindUser', params)


// 查询绑定人员

export const getBindOeAccountUser = (params) => request.get(`/admin/oeLogin/bindUser/`, {params})


// 查询当前登录用户关联账号

export const getCurrentUserBindOeAccountList = (params) => request.get('/admin/oeLogin/currentUser', {params})
/**
 *end by devT
 **/
