<template>
  <div class="feedback-box">
    <el-dialog
      :close-on-press-escape="isEdit || isAdd"
      append-to-body
      center
      title="意见收集"
      :visible.sync="showFeedBack"
      width="600px"
      top="40px"
      :close-on-click-modal="isEdit || isAdd"
      :show-close="isEdit || isAdd"
      :before-close="() => $emit('cancel')"
    >
      <div class="form-box">
        <p class="tip-text">
          为了您的更好体验、提高办公效率、现在针对平台进行以下调研。希望提出宝贵的意见
        </p>
        <el-form
          ref="feedBackForm"
          :rules="rules"
          :model="form"
          label-position="top"
        >
          <el-form-item label="1.你觉得现在小说系统中最缺少的是什么？">
            <el-input
              type="textarea"
              :rows="4"
              v-model="form.novelLack"
            ></el-input>
          </el-form-item>
          <el-form-item label="2.在使用过程中，遇到过哪些问题？">
            <el-input
              :rows="4"
              type="textarea"
              v-model="form.novelQuestion"
            ></el-input>
          </el-form-item>
          <el-form-item label="3.小说系统中最不能让你忍受的是什么？">
            <el-input
              :rows="4"
              type="textarea"
              v-model="form.novelFeel"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="4.你对小说系统有什么(需求)想说的？"
            prop="novelNeeds"
          >
            <el-input
              :rows="4"
              type="textarea"
              v-model="form.novelNeeds"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer">
        <el-button type="primary" :loading="loading" @click="handleSubmit"
          >提交</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { addUserFeedback, updateUserFeedback } from "@/api/user";
export default {
  props: ["showFeedBack", "detail", "isAdd"],
  data() {
    return {
      form: {},
      loading: false,
      rules: {
        novelNeeds: [
          { required: true, message: "请填写内容", trigger: "blur" },
        ],
      },
      isEdit: false,
    };
  },
  watch: {
    showFeedBack: function (nv) {
      if (nv && this.detail) {
        this.form = {
          ...this.detail,
        };
        this.isEdit = true;
      } else {
        this.isEdit = false;
        this.form = {};
      }
    },
  },
  methods: {
    handleSubmit() {
      this.$refs.feedBackForm.validate((valid) => {
        if (valid) {
          this.loading = true;
          // let tpmId = this.$store.state.userInfo.id;
          // // let tpmId = 319;
          // console.log(tpmId, "tpmId");
          if (this.isEdit) {
            updateUserFeedback(this.form.id, {
              ...this.form,
            })
              .then((res) => {
                this.$message.success("修改成功");
                this.$emit("close");
                this.$emit("fresh");
              })
              .finally(() => {
                this.loading = false;
              });
          } else {
            addUserFeedback({
              // adminUserId: tpmId,
              ...this.form,
            })
              .then((res) => {
                this.$emit("close");

                if (!this.isAdd) {
                  // this.$confirm(
                  //   "提交成功后,您可在用户管理->意见反馈下进行新增及查看，感谢的您白忙中的回复，谢谢！",
                  //   "提示",
                  //   {
                  //     confirmButtonText: "确定",
                  //     cancelButtonText: "取消",
                  //     type: "warning",
                  //   }
                  // ).then(() => {});
                  this.$alert(
                    "感谢您白忙中的回复，您可在用户管理->意见反馈下进行新增及查看，谢谢！",
                    "提交成功",
                    {
                      confirmButtonText: "确定",
                    }
                  );
                  // this.$message.success(
                  //   "提交成功后,您可在用户管理->意见反馈下进行新增及查看，感谢的您白忙中的回复，谢谢！"
                  // );
                } else {
                  this.$message.success("新增成功");
                  this.$emit("fresh");
                }
              })
              .finally(() => {
                this.loading = false;
              });
          }
        }
      });
    },
  },
};
</script>

<style lang='scss' scoped>
.tip-text {
  // text-indent: 12px;
  line-height: 18px;
  margin-bottom: 10px;
  // padding: 0 12px;
  font-size: 12px;
}
</style>