
let operateRealTime = {
  authority: [0, 2],
  main: {
    path: "/home/operateRealTime",
    title: "运营决策者驾驶舱"
  },
  children: null
};
let customerBoard = {
  authority: [0, 1, 2, 3, 999],
  main: {
    path: "/home/customer-analysis-board",
    title: "客户分析看板"
  },
  children: null
};

let put = {
  main: {
    path: "/home/put",
    title: "投放实时数据"
  },
  children: [
    {
      authority: [0, 3], // !用户角色Id(homeRole)
      path: "/home/put/put-decision-maker",
      title: "投放决策驾驶舱"
    },
    {
      authority: [0, 1, 3],
      path: "/home/put/putter-board",
      title: "投手驾驶舱"
    }
  ]
};
function getHomeRole() {
  let vuex = JSON.parse(localStorage.getItem("vuex"));
  let homeRoleArr =
    vuex && vuex.userInfo &&
    vuex.userInfo.homeRole &&
    vuex.userInfo.homeRole.split(",").map(item => Number(item));

  let needRouter = {
    path: "/home",
    title: "监控台",
    icon: "icon-jiankongtai-copy",
    children: []
  };
  let homeRoleObj = {
    operateRealTime:
      homeRoleArr &&
        homeRoleArr.some(val => operateRealTime.authority.includes(val))
        ? operateRealTime
        : null,
    put: homeRoleArr && recurrenceRoute(homeRoleArr, put.children),
    customerBoard:
      homeRoleArr &&
        homeRoleArr.some(val => customerBoard.authority.includes(val)) ? customerBoard : null,
  };
  Object.keys(homeRoleObj).forEach(item => {
    let val = homeRoleObj[item];
    if (["operateRealTime", "customerBoard"].includes(item)) {
      if (val) {
        needRouter.children.push({
          path: val.main.path,
          title: val.main.title
        });
      }
    }
    if (item === "put") {
      // console.log(val);
      if (val && val.length > 0) {
        needRouter.children.push({
          path: "/put",
          title: "投手实时数据",
          children: val
        });
      }
    }
  });
  return needRouter;
}
function rejectRouter(homeRoleArr, arr) {
  if (!Array.isArray(arr)) {
    return;
  }
  arr = arr.filter(item => {
    return homeRoleArr.some(val => {
      return item.authority.includes(val);
    });
  });
  for (let i = 0; i < arr.length; i++) {
    let item = arr[i];
    if ("children" in item && Array.isArray(item.children)) {
      arr.children = rejectRouter(homeRoleArr, item);
    }
  }
  return arr;
}

function recurrenceRoute(homeRoleArr, sub) {
  if (!sub || sub.length === 0) return [];
  let arr = sub.filter(item => {
    return homeRoleArr.some(val => {
      return item.authority.includes(val);
    });
  });
  arr.forEach(item => {
    if ("children" in item && Array.isArray(item.children)) {
      item.children = rejectRouter(homeRoleArr, item.children);
    }
  });
  return arr;
}

export const menuList = [
  getHomeRole(),
  // {
  //   path: "/consume-management",
  //   title: "消耗管理",
  //   icon: "icon-xiaohaoguanli"
  //   // color: '#5F6E82',
  // },
  {
    path: "/bookManagement",
    title: "书籍管理",
    icon: "icon-shuji1"
    // color: '#5F6E82',
  },
  {
    path: "/statistics",
    title: "数据统计",
    icon: "icon-shujutongji",
    children: [
      {
        path: "/statistics/yearDetail",
        title: "年度统计",
        color: "#5F6E82"
      },
      {
        path: "/statistics/operate",
        title: "运营数据",
        children: [
          {
            path: "/statistics/operate/operateData",
            title: "运营数据",
            color: "#5F6E82"
          },
          {
            path: "/statistics/operate/channelData",
            title: "每日公众号数据",
            color: "#5F6E82"
          },
          {
            path: "/statistics/operate/assess",
            title: "运营考核",
            color: "#5F6E82"
          },
          {
            path: "/statistics/operate/operateStatic",
            title: "运营统计",
            color: "#5F6E82"
          },
          {
            path: "/statistics/operate/intervalOperate",
            title: "运营区间",
            color: "#5F6E82"
          }
        ]
      },
      {
        path: "/statistics/consume",
        title: "每日消耗",
        children: [
          {
            path: "/statistics/consume/user",
            title: "每日用户消耗",
            color: "#5F6E82"
          },
          {
            path: "/statistics/consume/channel",
            title: "每日公众号消耗",
            color: "#5F6E82"
          },
          {
            path: "/statistics/consume/book",
            title: "每日书籍消耗",
            color: "#5F6E82"
          },
          {
            path: "/statistics/consume/ad",
            title: "今日广告",
            color: "#5F6E82"
          }
        ]
      },
      {
        path: "/statistics/order",
        title: "订单明细",
        children: [
          {
            path: "/statistics/order/consumeCharging",
            title: "消耗充值",
            color: "#5F6E82"
          },
          {
            path: "/statistics/order/orderDetail",
            title: "订单明细",
            color: "#5F6E82"
          }
          // ,
          // {
          //   path: '/order/momentCharging',
          //   title: '时刻新增充值',
          //   color: '#5F6E82'
          // }
        ]
      },
      // 财务
      {
        path: "/statistics/finance",
        title: "财务统计",
        children: [
          {
            path: "/statistics/finance/everydayData",
            title: "财务日情况报表",
            color: "#5F6E82"
          },
          {
            path: "/statistics/finance/bookData",
            title: "财务书籍统计",
            color: "#5F6E82"
          },
          {
            path: "/statistics/finance/salaryData",
            title: "薪资管理",
            color: "#5F6E82"
          },
          {
            path: "/statistics/finance/profitData",
            title: "利润统计",
            color: "#5F6E82"
          }
        ]
      },
      {
        path: "/statistics/channelData",
        title: "公众号数据",
        children: [
          {
            path: "/statistics/channelData/intervalUser",
            title: "区间用户消耗",
            color: "#5F6E82"
          },
          {
            path: "/statistics/channelData/intervalChannel",
            title: "区间公众号消耗",
            color: "#5F6E82"
          },
          {
            path: "/statistics/channelData/intervalBook",
            title: "区间书籍消耗",
            color: "#5F6E82"
          },
          {
            path: "/statistics/channelData/testIntervalBook",
            title: "测试书籍消耗",
            color: "#5F6E82"
          },
          {
            path: "/statistics/channelData/intervalPutAnalysis",
            title: "区间投放分析",
            color: "#5F6E82"
          }
        ]
      },
      {
        path: "/statistics/rankingStatistics",
        title: "排行统计",
        children: [
          {
            path: "/statistics/rankingStatistics/putCrowdRank",
            title: "投手实时排行",
            color: "#5F6E82"
          },
          {
            path: "/statistics/rankingStatistics/incomeRank",
            title: "收益排行",
            color: "#5F6E82"
          },
          {
            path: "/statistics/rankingStatistics/userRank",
            title: "用户排行",
            color: "#5F6E82"
          }
        ]
      },
      {
        path: "/statistics/multiStatistics",
        title: "累计统计",
        children: [
          {
            path: "/statistics/multiStatistics/multi",
            title: "累计统计",
            color: "#5F6E82"
          },
          {
            path: "/statistics/multiStatistics/month",
            title: "月度统计",
            color: "#5F6E82"
          },
          {
            path: "/statistics/multiStatistics/monthDetail",
            title: "月度统计详情",
            color: "#5F6E82"
          },
          {
            path: "/statistics/multiStatistics/costRecharge",
            title: "小说成本",
            color: "#5F6E82"
          },
          // {
          //   path: "/statistics/multiStatistics/yearDetail",
          //   title: "年度统计",
          //   color: "#5F6E82"
          // },
        ]
      },
      {
        path: "/statistics/userRecharge",
        title: "用户复充"
      },
      {
        path: "/statistics/platformStatic",
        title: "平台统计"
        // color: '#5F6E82'
      },
      {
        path: "/statistics/bookValueAnalysis",
        title: "书籍价值分析"
        // color: '#5F6E82'
      },
      {
        path: "/statistics/wechatKey",
        title: "关键字统计",
        children: [
          {
            path: "/statistics/wechatKey/keywordsDetail",
            title: "明细"
          },
          {
            path: "/statistics/wechatKey/keywordsStatic",
            title: "统计"
          }
        ]
      },
      {
        path: "/statistics/tagLink",
        title: "标签链接"
      },
    ]
  },
  {
    path: "/message",
    title: "消息管理",
    icon: "icon-xiaoxiguanli",
    children: [
      {
        path: "/message/service",
        title: "客服消息群发"
        // color: '#5F6E82'
      },
      {
        path: "/message/delay",
        title: "关注延迟回复"
      },
      {
        path: "/message/tag",
        title: "标签群发"
        // color: '#5F6E82'
      },
      {
        path: "/message/hour",
        title: "小时群发"
        // color: '#5F6E82'
      },
      {
        path: '/message/key-word',
        title: '关键字回复'
      },
      {
        path: "/message/applet",
        title: "小程序群发"
      },
      {
        path: "/message/template",
        title: "模板消息",
        icon: "icon-xiaoshiqunfa",
      },
      {
        path: "/message/resource-library",
        title: "素材库"
      },
      {
        path: '/message/unToppedOrder',
        title: '未支付订单',
        children: [
          {
            path: '/message/unToppedOrder/list',
            title: '列表'
          },
          {
            path: '/message/unToppedOrder/message',
            title: '客服消息'
          }
        ]
      },
      {
        path: "/message/mediaResource",
        title: "图文素材",
        children: [
          {
            path: "/message/mediaResource/media",
            title: "图文",
            color: "#5F6E82"
          },
          {
            path: "/message/mediaResource/resource",
            title: "素材",
            color: "#5F6E82"
          }
        ]
      },
      {
        path: '/message/exclusiveBook',
        title: '书籍价格'
      },
      {
        path: '/message/batchSend',
        title: '批量发送'
      }
    ]
  },
  {
    path: "/novel-put-management",
    title: "广告投放",
    icon: "icon-guanggaotoufang",
    children: [
      {
        path: "/novel-put-management/materialLibrary",
        title: "素材库"
      },
      {
        path: "/novel-put-management/novelProjectManager/novel-today/ad-group",
        title: "投放管理",
        children: [
          {
            path: "/consume-management",
            title: "消耗管理",
            // color: '#5F6E82',
          },
          {
            path:
              "/novel-put-management/novelProjectManager/novel-today/ad-group",
            title: "今日广告",
            color: "#5F6E82"
          },
          {
            path:
              "/novel-put-management/novelProjectManager/novel-today/put-data",

            title: "投放计划",
            color: "#5F6E82"
          }
        ]
      },
      // {
      //   path: "/novel-put-management/deliveryMaterialManage",
      //   title: "投放素材管理",
      //   // color: '#5F6E82'
      //   children: [
      //     {
      //       path: "/novel-put-management/deliveryMaterialManage/client",
      //       title: "服务商",
      //       color: "#5F6E82"
      //     },
      //     {
      //       path: "/novel-put-management/deliveryMaterialManage/page-manage",
      //       title: "推广页",
      //       color: "#5F6E82"
      //     },
      //     {
      //       path:
      //         "/novel-put-management/deliveryMaterialManage/material-manage",
      //       title: "投放素材",
      //       color: "#5F6E82"
      //     }
      //     // {
      //     //   path:'/'
      //     // }
      //   ]
      // },
      {
        path: "/wx-link",
        title: "微链管理"
      },
      {
        path: "/novel-put-management/putDispatch",
        title: "投放派单",
        children: [
          {
            path: "/novel-put-management/putDispatch/puter",
            title: "投手",
            color: "#5F6E82"
          },
          {
            path: "/novel-put-management/putDispatch/designer",
            title: "美工",
            color: "#5F6E82"
          }
        ]
      },
      {
        path: '/landing-page-library',
        title: '落地页素材',
        path: '/novel-put-management/landing-page-library'
      }
      // {
      //   path:'/novel-put-management/deliveryMaterialManage',
      //   title:'投放素材管理'
      // }
    ]
  },
  {
    path: '/small-plane',
    title: '批量投放',
    icon: 'icon-guanggaotoufang',
    children: [
      {
        path: '/toutiao',
        title: '头条投放',
        children: [
          {
            path: '/small-plane/toutiao/batch-put',
            title: '批量投放'
          },
          {
            path: '/small-plane/toutiao/change-track',
            title: '转化追踪'
          },
          {
            path: '/small-plane/toutiao/promotion-card',
            title: '推广卡片'
          }
        ]
      },
      {
        path: '/small-plane/baidu',
        title: '百度投放',
        children: [
          {
            path: '/small-plane/baidu/put-plan',
            title: '投放计划'
          },
          {
            path: '/small-plane/baidu/landing-page',
            title: '落地页'
          }
        ]
      },
      {
        path: '/small-plane/Tencent-put',
        title: 'ADQ投放',
        children: [
          {
            path: '/small-plane/Tencent-put/ad-put-list',
            title: '投放管理'
          },
        ]
      },
      {
        path:'/small-plane/weixin-put',
        title:'微信投放',
        children:[
          {
            path:'/small-plane/weixin-put/plan',
            title:'推广计划'
          },
          {
            path: "/small-plane/weixin-put/promotion",
            title: '推广页'
          }
        ]
      }

    ]
  },
  {
    path: "/account-management",
    title: "账号管理",
    icon: "icon-zhanghaoguanli2",
    children: [
      {
        path: "/account-management/platform-account",
        title: "平台账号"
      },
      {
        path: "/account-management/channel-list",
        title: "公众号"
      },
      {
        path: "/account-management/account-login",
        title: "书城记录"
      },
      {
        path: "/account-management/baidu-account",
        title: "百度账号"
      },
      {
        path: '/account-management/ocean-list',
        title: '巨量账号'
      },
      {
        path: '/account-management/ocean-account',
        title: '巨量引擎'
      },
      {
        path: "/account-management/ADQ",
        title: 'ADQ'
      }
    ]
  },
  {
    path: "/classify",
    title: "分类管理",
    icon: "icon-fenleifenxi",
    children: [
      {
        path: "/classify/customer/title",
        title: "客服消息"
      },
      // {
      //   path: "/classify/book",
      //   title: "书籍管理"
      // },
      {
        path: "/classify/plate",
        title: "板块管理"
      },
      {
        path: "/classify/resource-management",
        title: "素材管理"
      },
      // {
      //   path: '/classify/extend-management',
      //   title: '巨量引擎'
      // }
    ]
  },
  {
    path: "/user-management",
    title: "用户管理",
    icon: "icon-yonghuguanli",
    children: [
      {
        path: "/user-management/user-manage",
        title: "用户管理"
      },
      {
        path: "/user-management/book-user",
        title: "书城用户"
      },
      {
        path: "/user-management/user-attribute",
        title: "用户属性",
        icon: ""
      },
      {
        path: "/user-management/user-feedback",
        title: "意见反馈",
      }
    ]
  },
  {
    path: '/user-tags',
    title: '用户标签',
    icon: "icon-yonghuguanli",

  },
  {
    path: '/documentManage',
    title: '文档管理',
    icon: "el-icon-folder-add",
    children: [
      {
        path: '/documentManage/readOnly',
        title: '文档查询'
      },
      {
        path: '/documentManage/setting',
        title: '文档设置'
      },
    ]
  },
  {
    path: '/material-warehouse',
    title: '素材管理',
    icon: "icon-yonghuguanli",
    children: [
      {
        path: '/material-warehouse/library',
        title: '素材库'
      },
      {
        path: '/material-warehouse/tags',
        title: '标签管理'
      },
      {
        path: '/material-warehouse/doc',
        title: '文案库'
      }
    ]
  },
  {
    path: '/groundPage',
    title: '回传设置',
    icon: "icon-xiaoxiguanli",
    children: [
      // {
      //   path: "/groundPage/landingPage",
      //   title: "落地页",
      // },
      {
        path: "/groundPage/landingPageTT",
        title: "头条落地页",
      },
      {
        path: "/groundPage/landingPageBD",
        title: "百度落地页",
      },
      {
        path: "/groundPage/quckApp",
        title: "快应用",
        children: [
          {
            path: "/groundPage/quckApp/quckAppSet",
            title: "快应用设置",
          },
          {
            path: "/groundPage/quckApp/callBackData",
            title: "回传数据",
          }
        ]
      },
      // {
      //   path:'/'
      // }
    ]
  },
  // {
  //   path: "/staticsWarning",
  //   title: "数据告警",
  //   icon: "icon-jinggao"
  // },
  {
    path: "/message/send-failed",
    title: "系统通知",
    icon: "icon-xitongtongzhi"
  },
  {
    path: "/system-management",
    title: "系统管理",
    icon: "icon-xitongguanli",
    children: [
      {
        path: "/system-management/organize",
        title: "组织架构"
      },
      {
        path: "/system-management/auth",
        title: "权限管理"
      },
      {
        path: "/system-management/log",
        title: "日志管理"
      },
      {
        path: "/system-management/field",
        title: "字段管理"
      },
      {
        path: "/system-management/sync",
        title: "数据同步"
      },
      {
        path: "/system-management/label",
        title: "标签基数"
      }
    ]
  }
];

export const classifyMenu = [
  {
    path: "/classify/customer",
    title: "客服消息",
    icon: "icon-kefu3",
    children: [
      {
        path: "/classify/customer/title",
        title: "标题管理",
        icon: "icon-biaotiguanli"
      },
      {
        path: "/classify/customer/image",
        title: "图片管理",
        icon: "icon-tupianguanli1"
      },
      {
        path: "/classify/customer/description",
        title: "描述管理",
        icon: "icon-miaoshuguanli"
      },
      {
        path: "/classify/customer/templateManage",
        title: "模板管理",
        icon: "icon-mobanguanli"
      }
    ]
  },
  // {
  //   path: "/classify/book",
  //   title: "书籍管理",
  //   icon: "icon-shuji"
  // },
  {
    path: "/classify/plate",
    title: "板块管理",
    icon: "icon-hebingxingzhuang2x"
  },
  {
    path: "/classify/resource-management",
    title: "素材管理"
  },
  // {
  //   path: '/classify/extend-management',
  //   title: '巨量引擎'
  // },
];

export const systemMenu = [
  {
    path: "/system-management/organize",
    title: "组织架构",
    icon: ""
  },
  {
    path: "/system-management/auth",
    title: "权限管理",
    icon: ""
  },
  {
    path: "/system-management/log",
    title: "日志管理",
    icon: ""
  },
  {
    path: "/system-management/field",
    title: "字段管理",
    icon: ""
  },
  {
    path: "/system-management/sync",
    title: "数据同步",
    icon: ""
  },
  {
    path: "/system-management/label",
    title: "标签基数",
    icon: ""
  }
];

export const userMenu = [
  {
    path: "/user-management/user-manage",
    title: "用户管理",
    icon: ""
  },
  {
    path: "/user-management/book-user",
    title: "书城用户",
    icon: ""
  },
  {
    path: "/user-management/user-attribute",
    title: "用户属性",
    icon: ""
  },
  {
    path: "/user-management/user-feedback",
    title: "意见反馈",
  }
];

export const novelPutMenu = [
  {
    path: "/novel-put-management/client",
    title: "服务商",
    icon: ""
  },
  {
    path: "/novel-put-management/deliveryMaterialManage",
    title: "投放管理",
    icon: ""
  },
  {
    path: "/novel-put-management/deliveryMaterialManage",
    title: "投放素材管理",
    icon: ""
  }
];

export const messageMenu = [
  {
    path: "/message/service",
    title: "客服消息群发",
    icon: "icon-kefu3"
  },
  {
    path: "/message/delay",
    title: "关注延迟回复",
    icon: "icon-yanchihuifu"
  },
  {
    path: "/message/tag",
    title: "标签群发",
    icon: "icon-biaoqianqunfa"
  },
  {
    path: "/message/hour",
    title: "小时群发",
    icon: "icon-xiaoshiqunfa"
  },
  {
    path: '/message/key-word',
    title: '关键字回复',
    icon: "icon-xiaoshiqunfa"
  },
  {
    path: "/message/applet",
    title: "小程序群发"
  },
  {
    path: "/message/template",
    title: "模板消息",
    icon: "icon-xiaoshiqunfa",
    special: true //用于横向菜单特殊处理
  }
];

export const accountMenu = [
  {
    path: "/account-management/platform-account",
    title: "平台账号"
  },
  {
    path: "/account-management/channel-list",
    title: "公众号"
  },
  {
    path: "/account-management/account-login",
    title: "书城记录"
  },
  {
    path: "/account-management/baidu-account",
    title: "百度账号"
  },
  {
    path: '/account-management/ocean-list',
    title: '巨量账号'
  },
  {
    path: '/account-management/ocean-account',
    title: '巨量引擎'
  },
  {
    path: '/account-management/ADQ',
    title: 'ADQ'
  }
];

export const novelPutMenu2 = [
  {
    path: "/novel-put-management/novelProjectManager/novel-today/ad-group",
    title: "今日广告"
  },
  {
    path: "/novel-put-management/novelProjectManager/novel-today/put-data",
    title: "投放计划"
  }
];

export const tagsNavList = [
  {
    path: '/material-warehouse/tags/tags-group',
    title: '标签组'
  },
  {
    path: '/material-warehouse/tags/tags-item',
    title: '标签库'
  }
]
