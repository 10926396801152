import { formatTime } from "./utils";

const today = formatTime(Date.now(), "yyyy/MM/dd");
const yesterday = formatTime(Date.now() - 1000 * 24 * 3600, "yyyy/MM/dd");

export const pickerOptions = {
  shortcuts: [
    {
      text: "本月",
      onClick(picker) {
        const days = new Date().getDate();
        const end = new Date(today + " 23:59:59");
        picker.$emit("pick", [
          new Date(end.getTime() - 3600 * 1000 * 24 * days + 1000),
          end
        ]);
      }
    },
    {
      text: "今天",
      onClick(picker) {
        const end = new Date(today + " 23:59:59");
        picker.$emit("pick", [
          new Date(end.getTime() - 3600 * 1000 * 24 + 1000),
          end
        ]);
      }
    },
    {
      text: "昨天",
      onClick(picker) {
        const end = new Date(today + " 23:59:59");
        picker.$emit("pick", [
          new Date(end.getTime() - 3600 * 1000 * 24 * 2 + 1000),
          new Date(end.getTime() - 3600 * 1000 * 24)
        ]);
      }
    },
    {
      text: "最近一周",
      onClick(picker) {
        const end = new Date(today + " 23:59:59");
        picker.$emit("pick", [
          new Date(end.getTime() - 3600 * 1000 * 24 * 8 + 1000),
          end
        ]);
      }
    },
    {
      text: "最近一个月",
      onClick(picker) {
        const end = new Date(today + " 23:59:59");
        picker.$emit("pick", [
          new Date(end.getTime() - 3600 * 1000 * 24 * 30 + 1000),
          end
        ]);
      }
    }
  ],
  disabledDate(time) {
    return time > new Date(`${today} 23:59:59`);
  }
};

export const pickerOptionsC = {
  disabledDate(time) {
    return time > new Date(`${today} 23:59:59`);
  }
};

export const disableTodayOptions = {
  disabledDate(time) {
    return time > new Date(`${today} 23:59:59`);
  }
};
// 今天之前日期不可选
export const disableIncludeTodayOptions = {
  disabledDate(time) {
    return time < new Date(`${yesterday} 23:59:59`);
  }
};

export const yesterdayOptions = {
  shortcuts: [
    {
      text: "本月",
      onClick(picker) {
        const days = new Date().getDate() - 1;
        const end = new Date(yesterday + " 23:59:59");
        picker.$emit("pick", [
          new Date(end.getTime() - 3600 * 1000 * 24 * days + 1000),
          end
        ]);
      }
    },
    {
      text: "今天",
      onClick(picker) {
        const end = new Date(yesterday + " 23:59:59");
        picker.$emit("pick", [
          new Date(end.getTime() - 3600 * 1000 * 24 + 1000),
          end
        ]);
      }
    },
    {
      text: "昨天",
      onClick(picker) {
        const end = new Date(yesterday + " 23:59:59");
        picker.$emit("pick", [
          new Date(end.getTime() - 3600 * 1000 * 24 * 2 + 1000),
          new Date(end.getTime() - 3600 * 1000 * 24)
        ]);
      }
    },
    {
      text: "最近一周",
      onClick(picker) {
        const end = new Date(yesterday + " 23:59:59");
        picker.$emit("pick", [
          new Date(end.getTime() - 3600 * 1000 * 24 * 8 + 1000),
          end
        ]);
      }
    },
    {
      text: "最近一个月",
      onClick(picker) {
        const end = new Date(yesterday + " 23:59:59");
        picker.$emit("pick", [
          new Date(end.getTime() - 3600 * 1000 * 24 * 30 + 1000),
          end
        ]);
      }
    }
  ],
  disabledDate(time) {
    return time > new Date(`${yesterday} 23:59:59`);
  }
};

export const platformList = [
  "掌中云",
  "阅文",
  "追书云",
  "阳光书城",
  "悠书阁",
  "网易文鼎",
  "七悦",
  "花生书城",
  "海读",
  "绪风",
  "掌读",
  "阳光快应用",
  "国风",
  "番茄快应用",
  "看书网",
  "看书网快应用",
  "点众快应用",
  "黑岩快应用",
];

// export const labelList = ['抖音', '爱奇艺', '朋友圈', '百度', '广点通GDT', '朋友圈-宇尘', '知乎', 'vivo', '公众号', "360", "GDT-宇尘", '头条']
export const labelList = [
  {
    label: "抖音",
    value: 0,
    show: false
  },
  {
    label: "爱奇艺",
    value: 1,
    show: false
  },
  {
    label: "朋友圈",
    value: 2,
    show: true
  },
  {
    label: "百度",
    value: 3,
    show: true
  },
  {
    label: "广点通GDT",
    value: 4,
    show: true
  },
  {
    label: "朋友圈-宇尘",
    value: 5,
    show: false
  },
  {
    label: "知乎",
    value: 6,
    show: false
  },
  {
    label: "vivo",
    value: 7,
    show: false
  },
  {
    label: "公众号",
    value: 8,
    show: true
  },
  {
    label: "360",
    value: 9,
    show: false
  },
  {
    label: "GDT-宇尘",
    value: 10,
    show: false
  },
  {
    label: "抖音",
    value: 11,
    show: true
  },
  {
    label: "头条",
    value: 12,
    show: true
  }
];

/**
 *( (  \.---./  ) )
 * '.__/o   o\__.'
 *     by devT
 **/

// 客服消息支持平台
export const messagePlatformList = [0, 1, 3, 4, 5, 6, 7, 8, 12, 14];
export const messagePlatformListDetail = [
  {
    name: "掌中云",
    id: 0
  },
  {
    name: "阅文",
    id: 1
  },
  {
    name: "阳光书城",
    id: 3
  },
  {
    name: "悠书阁",
    id: 4
  },
  {
    name: "网易文鼎",
    id: 5
  },
  {
    name: "七悦",
    id: 6
  },
  {
    name: "花生书城",
    id: 7
  },
  {
    name: "海读",
    id: 8
  },
  // {
  //   name: '绪风',
  //   id: 9
  // },
  // {
  //   name:'掌读',
  //   id:10
  // },
  // {
  //   name:'阳光快应用',
  //   id:11
  // }
  {
    name: "国风",
    id: 12
  },
  {
    name: "看书网",
    id: 14
  }
];

// 客服消息时间范围选择
export const messagePickerOptions = {
  shortcuts: [
    {
      text: "今天",
      onClick(picker) {
        picker.$emit("pick", new Date());
      }
    },
    {
      text: "昨天",
      onClick(picker) {
        const date = new Date();
        date.setTime(date.getTime() - 3600 * 1000 * 24);
        picker.$emit("pick", date);
      }
    },
    {
      text: "一周前",
      onClick(picker) {
        const date = new Date();
        date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
        picker.$emit("pick", date);
      }
    }
  ]
};
// 平台相关信息
export const hostArr = [
  {
    host: "https://inovel.818tu.com",
    url: "https://inovel.818tu.com"
  },
  {
    host: "https://open.yuewen.com",
    url: "https://open.yuewen.com/new/dashboard"
  },
  {
    host: "https://channel.zhuishuyun.com",
    url: "https://channel.zhuishuyun.com/notify"
  },
  {
    host: "https://admin.kydba.cn",
    url: "https://admin.kydba.cn/admin/notice/index?ref=addtabs"
  },
  {
    host: "https://novel.qinronread.com",
    url: "https://novel.qinronread.com"
  },
  {
    host: "https://bi.reading.163.com",
    url: "https://bi.reading.163.com/spa/statistic/order"
  },
  {
    host: "https://zhangwenwenhua.com",
    url: "https://zhangwenwenhua.com/admin/notice/index?ref=addtabs"
  },
  {
    host: "https://ad.rlcps.cn/#/user/login/redirect=%2F",
    url: "https://ad.rlcps.cn/#/"
  },
  // 海读
  {
    host: "http://managers.haidubooks.com/login?redirect=%2F",
    // url: "http://managers.haidubooks.com"
    url: "http://managers.haidubooks.com/defaultSummary/summaryInfo"
    // url:'http://managers.haidubooks.com/'
  },
  // 绪风书城
  {
    host: "",
    url: ""
  },
  // 掌读
  {
    host: "",
    url: ""
  },
  // 阳光快应用
  {
    host: "",
    url: ""
  },
  //国风
  {
    host: "https://bi.reading.163.com",
    url: "https://bi.reading.163.com"
  }
];

// 用户属性
export const userAttributeList = [
  {
    key: "sex",
    value: "性别"
  },
  {
    key: "province",
    value: "省份"
  },
  {
    key: "city",
    value: "城市"
  },
  {
    key: "nickname",
    value: "昵称"
  },
  {
    key: "subscribeDate",
    value: "关注日期"
  },
  {
    key: "spaceDay",
    value: "关注天数"
  },
  {
    key: "expTime",
    value: "互动有效期"
  },
  {
    key: "isRecharge",
    value: "是否充值"
  },
  {
    key: "totalRowRecharge",
    value: "累计充值"
  },
  {
    key: "rechargeNum",
    value: "充值次数"
  },
  {
    key: "lastRechargeDate",
    value: "最近充值日期"
  },
  {
    key: "rechargeSpaceDay",
    value: "充值间隔"
  },
  {
    key: "rfm",
    value: "rfm"
  },
  {
    key: "isMember",
    value: "vip"
  },
  {
    key: "isCoupon",
    value: "优惠敏感度"
  },
  {
    key: "kandian",
    value: "剩余书币"
  },
  {
    key: "kanDianRate",
    value: "币消耗率"
  },
  {
    key: "threeWeeksRecharge",
    value: "是否连续付费"
  },
  {
    key: "sevenRecharge",
    value: "	最近7日充值"
  }
];

export const userAttributes = {
  sex: {
    name: "性别",
    base: 1
  },
  province: {
    name: "省份",
    base: 1
  },
  city: {
    name: "城市",
    base: 1
  },
  nickname: {
    name: "昵称",
    base: 1
  },
  subscribeDate: {
    name: "关注日期",
    base: 1
  },
  spaceDay: {
    name: "关注天数",
    base: 1
  },
  expTime: {
    name: "互动有效期",
    base: 1
  },
  isRecharge: {
    name: "是否充值",
    base: 0
  },
  totalRowRecharge: {
    name: "累计充值",
    base: 0
  },
  rechargeNum: {
    name: "充值次数",
    base: 0
  },
  rechargeSpaceDay: {
    name: "充值间隔",
    base: 0
  },
  lastRechargeDate: {
    name: "最近充值日期",
    base: 0
  },
  rfm: {
    name: "rfm",
    base: 0
  },
  isMember: {
    name: "vip",
    base: 0
  },
  isCoupon: {
    name: "优惠敏感度",
    base: 0
  },
  kandian: {
    name: "剩余书币",
    base: 0
  },
  kanDianRate: {
    name: "币消耗率",
    base: 0
  },
  threeWeeksRecharge: {
    name: "是否连续付费",
    base: 0
  },
  sevenRecharge: {
    name: "最近7日充值",
    base: 0
  }
};

/**
 *end by devT
 **/
