import request from './config'

/*
 * 用户登录
 * params { mobile,password }
 */
export function login({ tmpAuthCode, authorizationToken }) {
  return request.post('/admin/authorize/password/login', { tmpAuthCode, authorizationToken })
}

/*
 * 退出登录
 * params { mobile,password }
 */
export function logout() {
  return request.post('/admin/authorize/logout')
}

/*
 * 设置密码
 * params { type,oldPassword,password }
 */
export function updatePassword(params) {
  return request.put('/admin/adminUser', params)
}

/*
 * 获取用户列表
 * params { page,pageSIze,name }
 */
export function getUserList(params) {
  return request.get('/admin/adminUser/list', { params })
}

/*
 * 编辑用户
 * params { mobile,name,avatar,id }
 */
export function editUser({ id, ...data }) {
  if (id) {
    return request.put(`/admin/adminUser/${id}`, data)
  } else {
    return request.post('/admin/adminUser', data)
  }
}

/*
 * 删除用户
 * id
 */
export function deleteUser(id) {
  return request.delete(`/admin/adminUser/${id}`)
}

/*
 * 查询书城用户
 * params{params}
 *
 */

export function getBookPlatformUserList(params) {
  return request.get('/platform/user', { params })
}

/**
 * 
 * 用户标签列表 
 *  
 */

export function getUserTagList(params) {
  return request.get('/admin/menuTag', { params })
}

/*
 * 新增用户标签
 * params{params}
 * 
 */

export function addUserTag(params) {
  return request.post('/admin/menuTag', params)
}
export function editUserTag(id, params) {
  return request.put(`/admin/menuTag/${id}`, params)

}
// 标签明细

export function getUserTagDetail(id) {
  return request.get(`/admin/menuTag/${id}`)
}
//复制公众号菜单标签

export function copyTag(ids, params) {
  return request.put(`/admin/menuTag/copy/${ids}`, params)
}

// 批量上传

export function batchUpload(ids) {
  return request.put(`/admin/menuTag/status/${ids}`)
}

// 删除标签

export function delUserTag(id) {
  return request.delete(`/admin/menuTag/${id}`)
}

// 用户打标签

export function addUserNewTag(id) {
  return request.put(`/admin/menuTag/user/${id}`)
}

// 用户属性
export function getUserAttribute(params) {
  return request.get('/admin/user/attribute', { params })
}

// !获取插件版本插件版本

export function getPluginInfo() {
  return request.get('/put/plugVersion')
}


/******用户反馈相关******/

// 获取用户是否填写过
export function getUserIsFeedback() {
  return request.get(`/admin/questionnaire/hasAnswer`)
}
// 获取用户反馈列表
export function getUserFeedbackList(params) {
  return request.get(`/admin/questionnaire`, { params })
}
// 删除用户反馈
export function deleteUserFeedback(params) {
  return request.delete('/test', { params })
}
// 编辑用户反馈
export function updateUserFeedback(id, params) {
  return request.put(`/admin/questionnaire/${id}`, params)
}
// 新增用户反馈
export function addUserFeedback(params) {
  return request.post('/admin/questionnaire', params)
}
