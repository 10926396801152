import axios from 'axios'
import { app } from '../main'
import { getCookie, removeCookie } from '../assets/js/cookie'

// 创建axios实例
const instance = axios.create({
  baseURL: '/api',
  withCredentials: true
})
instance.defaults.timeout = 30000
// 添加请求拦截器
instance.interceptors.request.use(
  function (config) {
    // 在发送请求之前做些什么
    const headers = {
      Authorization: getCookie('token') ? getCookie('token') : ''
    }
    if (config.headers) {
      config.headers = Object.assign({}, headers, config.headers)
    } else {
      config.headers = headers
    }
    return config
  },
  function (error) {
    // 对请求错误做些什么
    // console.log(error)
    // Notify('程序异常，请联系客服');
    return Promise.reject(error)
  }
)

// 添加响应拦截器
instance.interceptors.response.use(
  function (response) {
    // 对响应数据做点什么
    if (response.data.code && Number(response.data.code) !== 0) {
      if (Number(response.data.code) === 401) {
        removeCookie('token')
        localStorage.clear()
        window.location.href = `${window.location.origin}${window.location.pathname}#/login`
      }
      // app.$message.error(response.data.msg)
      app.$message({
        showClose: true,
        message: response.data.msg,
        type: 'error'
      })
      return Promise.reject(response.data.msg)
    } else {
      // return (response.data.data === null) || response.data
      return response.data.data === null || response.data.data === undefined
        ? response.data
        : response.data.data
    }
  },
  function (error) {
    if (error.response) {
      const { status } = error.response
      if (status === 502) {
        // app.$message.error('服务器更新中，请稍后再试！')
        app.$message({
          showClose: true,
          message: '服务器更新中，请稍后再试！',
          type: 'error'
        })
      }
    }
    if (error) {
      // app.$message.error(error.response && error.response.data ? error.response.data : '网络异常')
        app.$message({
          showClose: true,
          message:
            error.response && error.response.data
              ? error.response.data
              : '网络异常',
          type: 'error'
        })
      return Promise.reject(error)
    }
    // 对响应错误做点什么
    return Promise.reject(error)
  }
)

export default instance
